import TokenManager from "Apis/TokenManager";
import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import Radio from "shared/Radio/Radio";
import Select from "shared/Select/Select";

interface Props { 
  isActive: boolean;
  onCloseActive: () => void;
  onOpenActive: () => void;
  setUserDetails: any;
  handleCreateOrder: () => void;
  setPickUpPoint:any,
  setDeliveryDate:any
}
const getTomorrowDateTime = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1); 
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const ShippingAddress: FC<Props> = ({
  isActive,
  onCloseActive,
  onOpenActive,
  setUserDetails,
  handleCreateOrder,
  setPickUpPoint,
  setDeliveryDate
}) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [postCode, setPostCode] = useState<string>("");
  const [houseNrExt, setHouseNrExt] = useState<string>("");
  const [houseNr, setHouseNr] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [phoneNr, setPhoneNr] = useState<string>("");
  const [pickupDateTime, setPickupDateTime] = useState(getTomorrowDateTime());

  useEffect(() => {
    const user = TokenManager.getUserDetails();
    if (user) {
      setFirstName(user.firstName || ""); 
      setLastName(user.lastName || "");
      setEmail(user.email || "");
      setCity(user?.shippingAddress?.city || "");
      setStreet(user?.shippingAddress?.street || "");
      setState(user?.shippingAddress?.state || "");
      setPostCode(user?.shippingAddress?.postCode || "");
      setHouseNrExt(user?.shippingAddress?.houseNrExt || "");
      setHouseNr(user?.shippingAddress?.houseNr || "");
      setCountry(user?.shippingAddress?.country || "NL");
      setPhoneNr(user.phoneNumber || "");
    }
  }, []);

  useEffect(() => {
    setUserDetails({
      street: street,
      city: city,
      state: state,
      postCode: postCode,
      houseNrExt: houseNrExt,
      country: country,
      houseNr:houseNr,
    });
  }, [firstName, lastName, street, city, country, state, postCode, houseNrExt,houseNr]);

  const handleDateTimeChange = (event:any) => {
    setPickupDateTime(event.target.value);
  };

  useEffect(()=>{
    setDeliveryDate(pickupDateTime)
  },[pickupDateTime])

  const renderShippingAddress = () => {
    return (
      <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
        <div className="p-6 flex flex-col sm:flex-row items-start">
          <span className="hidden sm:block">
            <svg
              className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="sm:ml-8">
            <h3 className=" text-slate-700 dark:text-slate-300 flex ">
              <span className="uppercase">SHIPPING ADDRESS</span>
              <svg
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            </h3>
            <div className="font-semibold mt-1 text-sm">
              <span className="">Wouterkensveld 36, 5581SC Waalre</span>
            </div>
          </div>
          <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
            onClick={onOpenActive}
          >
            Change
          </ButtonSecondary>
        </div>
        <div
          className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
            isActive ? "block" : "hidden"
          }`}
        >
          {/* ============ */}
          <div>
            <Label className="text-sm">Address type</Label>
            <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
              <Radio
                label={`<span class="text-sm font-medium">Home </span>`}
                id="false"
                name="Address-type"
                onChange={(e)=>setPickUpPoint(e)}
                defaultChecked
              /> 
              <Radio 
                label={`<span class="text-sm font-medium">Pick up point </span>`}
                id="true"
                onChange={(e)=>setPickUpPoint(e)}
                name="Address-type"
              />
              {/* <span class="font-light">(Delivery <span class="font-medium">9 AM - 5 PM</span>)</span>  */}
            </div>
          </div>

          <div>
            <Label className="text-sm">Delivery Date</Label>
            <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
            <input
            type="datetime-local"
            id="pickupDateTime"
            className="form-control"
            value={pickupDateTime}
            onChange={handleDateTimeChange}
            min={getTomorrowDateTime()} 
          />
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">First name</Label>
              <Input
                className="mt-1.5"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <Label className="text-sm">Last name</Label>
              <Input
                className="mt-1.5"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          {/* ============ */}
          <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
          <div className="flex-1">
              <Label className="text-sm">Address</Label>
              <Input
                className="mt-1.5"
                placeholder=""
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                type={"text"}
              />
            </div>
            <div className="flex-1">
              <Label className="text-sm">House nr</Label>
              <Input
                className="mt-1.5"
                placeholder=""
                value={houseNr}
                onChange={(e) => setHouseNr(e.target.value)}
                type={"text"}
              />
            </div>
            <div className="sm:w-1/3">
              <Label className="text-sm">House Nr prefix*</Label>
              <Input className="mt-1.5" placeholder="A "                 value={houseNrExt}
                onChange={(e) => setHouseNrExt(e.target.value)}/>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">City</Label>
              <Input
                className="mt-1.5"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div>
              <Label className="text-sm">Country</Label>
              <Select
                className="mt-1.5"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="Netherlands">Netherlands</option>
                <option value="Belguim">Belguim</option>
                <option value="Germany">Germany</option>
              </Select>
            </div>
          </div>

          {/* ============ */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
            <div>
              <Label className="text-sm">State/Province</Label>
              <Input
                className="mt-1.5"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div>
              <Label className="text-sm">Postal code</Label>
              <Input
                className="mt-1.5"
                value={postCode}
                onChange={(e) => setPostCode(e.target.value)}
              />
            </div>
          </div>

          {/* ============ */}
          <div className="flex flex-col sm:flex-row pt-6">
            <ButtonPrimary
              className="sm:!px-7 shadow-none"
              onClick={handleCreateOrder}
            >
              Save and next to Payment
            </ButtonPrimary>
            <ButtonSecondary
              className="mt-3 sm:mt-0 sm:ml-3"
              onClick={onCloseActive}
            >
              Cancel
            </ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };
  return renderShippingAddress();
};

export default ShippingAddress;
